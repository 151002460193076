<template>
    <v-container
        id="be-instrum"
        tag="section"
    >
        <v-row
            justify="center"
            v-if="packageZg.paytime >= dateNow"
        >        
            <v-col
                cols="12"
                lg="9"
                xl="7"
                class="block-media-lk"
            >
                <base-material-card
                    color="warning"
                    class="px-sm-8 px-md-16 px-2 py-3"
                >
                    <template v-slot:heading>
                        <div class="display-2 font-weight-light">
                            ZeitGard
                        </div>

                        <div class="subtitle-1 font-weight-light">
                            Рефссылка, инструкции
                        </div>
                    </template>

                    <v-card-text
                        class="mt-10 mb-10 card-media-lk"
                    >
                    <p class="mb-7">
                      ZEITGARD - инструмент для продажи бьюти гаджета
                      </p>
                      <p class="mb-10">
                        Цель - Увеличить заинтересованность клиента в приборе. Увеличить количество продаж. Возможность использования в клиентских чатах и соц сетях. Возможность заинтересовать бизнесом и прибором работников бьюти сферы.
                      </p>
                        <v-expansion-panels
                            popout
                            focusable
                            class="exp-panel-title"
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                        <span>
                                            <v-icon class="mr-2" color="green">mdi-numeric-1-box-multiple</v-icon>
                                            Твоя реферальная ссылка
                                        </span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <p class="mt-10">
                                        <v-icon class="mr-2" color="blue">mdi-arrow-right-bold-outline</v-icon>
                                        <router-link
                                            color="success"
                                            :to="{name: 'ZeitGard', query: { partner: $store.state.user.profile.lr_number }}"
                                        >
                                            Лендинг ZeitGard
                                        </router-link>
                                    </p>
                                    <p class="mb-n2">
                                        <v-icon class="mr-2" color="blue">mdi-arrow-down-bold-outline</v-icon>
                                        <strong>Твоя реферальная ссылка на лендинг ZeitGard</strong>:
                                        <v-text-field
                                            :value="`https://newlvl.net/zeitgard/?partner=${$store.state.user.profile.lr_number}`"
                                            readonly
                                            id="referLinkZg"
                                        ></v-text-field>
                                    </p>
                                    <v-btn
                                        color="gray"
                                        @click="copyRefLinkZg"
                                        class="mb-5"
                                    >
                                        Скопировать ссылку
                                    </v-btn>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                  <span>
                                      <v-icon class="mr-2" color="red">mdi-numeric-2-box-multiple</v-icon>
                                      Рекомендации по работе с инструментом
                                  </span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                  <p class="mt-10">
                                    Прежде всего не забывай, что любой инструмент - это твой помощник. Современный и эффективный помощник,
                                    но его использовать нужно только после того, как ты сам совершил действие:
                                  </p>
                                  <p class="mt-7">
                                    <ul>
                                      <li>сделал предложение или</li>
                                      <li>провел бизнес встречу или</li>
                                      <li>позвонил по телефону.</li>
                                    </ul>
                                  </p>
                                  <p class="mt-5">
                                    Сама по себе ссылка не работает!
                                  </p>
                                  <p class="mt-5">
                                    Твоя задача: заинтересовать кандидата или кандидатов (клиентов) тем, что внутри.
                                    Это может быть не только индивидуальный диалог, это может быть зум, прямой эфир, офлайн мероприятие.
                                    А уже после этого, нужно скинуть нужную ссылку и она эффективно завершит твой дело, доведя человека до
                                    решения купить замечательный немецкий продукт компании LR!
                                  </p>
                                    <p class="mt-10">
                                        Как использовать данную страничку для увеличения твоего заработка.
                                    </p>
                                    <p class="">
                                        Есть несколько вариантов:
                                    </p>
                                    <p class="mt-5">
                                        1. <strong>Помощь в продаже прибора:</strong> ты сделал предложение, рассказал про прибор, скинул этот лендинг.
                                    </p>
                                    <p>
                                        <v-icon class="mr-2" color="orange">mdi-alert-decagram</v-icon>
                                        Как правильно продавать мы учим на марафоне StarSales
                                        (<a href="https://vk.com/marafonstarsales" target="_blank">группа в ВК</a>,
                                         <a href="https://t.me/marafonstarsales" target="_blank">канал в Телеграмм</a>)
                                    </p>
                                    <p class="mt-5">
                                        2. <strong>Помощь в продаже твоих косметических услуг</strong> с помощью прибора: рассказал что даст процедура, что за прибор, скинул лендинг.
                                    </p>
                                    <p class="mt-5">
                                        3. <strong>Как предложение для бьюти мастеров</strong> и салонов по увеличению дохода: сделал предложение мастеру маникюра,
                                         рассказал как он с помощью прибора, процедур и продажи кремов может увеличить доход, скинул лендинг.
                                    </p>
                                    <p>
                                        <v-icon class="mr-2" color="orange">mdi-alert-decagram</v-icon>
                                        Как эффективно делать бизнес предложение для разных аудиторий мы учим на марафоне StarBuilding 
                                        (<a href="https://vk.com/star.building" target="_blank">группа в ВК</a>,
                                         <a href="https://t.me/marafon_starbuilding" target="_blank">канал в Телеграмм</a>)
                                    </p>
                                    <p class="mt-5">
                                        4. <strong>Стать экспертом по красоте</strong>: вести в соц сетях страницу интересно для данной ЦА и размещать этот лендинг в историях и постах.
                                         Рассказал про чудо-прибор в истории, рассказал про скидки и подарки, разместил свой лендинг.
                                    </p>
                                    <p>
                                        <v-icon class="mr-2" color="orange">mdi-alert-decagram</v-icon>
                                        Как правильно зарабатывать в соц сетях мы учим на марафоне StarNetwork 
                                        (<a href="https://vk.com/marafonstarnetwork" target="_blank">группа в ВК</a>,
                                         <a href="https://t.me/StarNetwork_marafon" target="_blank">канал в Телеграмм</a>).
                                    </p>
                                    <p class="mt-5">
                                        5. Так же можно (и нужно) использовать данный инструмент <strong>для работы по клиентскому чату</strong>: после того, как ты рассказал про прибор клиенту, скидываешь в клиентский чат ссылку на данный лендинг по Zeitgard с предложением ознакомиться с продуктом на удобном, красивом сайте.
                                    </p>
                                    <p class="mt-7">
                                        <strong>ПОДРОБНЕЕ ЧИТАЙ НИЖЕ👇</strong>
                                    </p>
                                    <v-divider class="mb-5"></v-divider>
                                    <p>
                                        Есть польза, которой может научится каждый, и она настолько круто выражена, что может стать твоим основным
                                        инструментом рекрутинга и продаж.
                                    </p>
                                    <p>
                                        <strong>Почему?</strong> Потому что эффект виден сразу после первой процедуры!!! 💰
                                    </p>
                                    <p>
                                        Я говорю про работу с прибором Zeitgard от компании LR.
                                    </p>
                                    <p>
                                        <strong>Все, что тебе нужно:</strong>
                                    </p>
                                    <p class="mb-n1">
                                        - приобрести прибор и все сопутствующие продукты для работы
                                    </p>
                                    <p class="mb-n1">
                                        - изучить несложную технику его применения
                                    </p>
                                    <p>
                                        - проверить его на себе, проверить его на одном из родственников.
                                    </p>
                                    <p>
                                        Всё. На этом подготовка закончилась.
                                    </p>
                                    <p>
                                        Теперь у тебя есть инструмент для зарабатывания денег и роста в сетевом 📈
                                        А….Еще нужно чтобы был инструмент New Level, и своя страничка по Zeitgard!)
                                    </p>
                                    <p>
                                        <strong>Твоя задача начать</strong> проводить процедуры сначала близкому окружению 👨‍👩‍👧‍👦,
                                         и при наборе опыта, можно начать работать онлайн и офлайн по твоему городу.
                                    </p>
                                    <p>
                                        Обязательно <strong>делай процедуры за деньги</strong> (бесплатно не ценится).
                                        Ты должен зарабатывать! Пусть вначале это будет даже небольшая сумма, допустим 500 руб., 
                                        для косметической процедуры это действительно очень дешево. Но не за просто так 😏.
                                    </p>
                                    <p>
                                        Правильно и красиво <strong>упакуй свое предложение и экспертность</strong> в вопросе красоты лица на своей
                                        страничке в соцсети (в том числе для этого лендинг).
                                    </p>
                                    <p>
                                        Обязательно делай <strong>акцент на большой скидке</strong>, что процедура стоит 2 000 руб, но ты делаешь ее человеку за 500 руб.
                                        <strong>Почему такая скидка?</strong>  - потому что клиент обещает тебе после процедуры написать отзыв в соцсетях и отметить тебя. Вот такие бартерные условия.
                                    </p>
                                    <p>
                                        Так ты соберешь положительные кейсы, которые впоследствии можешь использовать для масштабирования своего бизнеса 🚀.
                                    </p>
                                    <p>
                                        Во время процедуры, ты конечно рассказываешь про прибор и про другую продукцию компании ЛР. Стараешься совершить продажу продукции 🛍️. 
                                    </p>
                                    <p>
                                        Также тем кто активен, ты рассказываешь про возможность дохода, опять же на простом примере твоего прибора  продаешь идею бизнеса через такие процедуры. Все просто, учишь тому, что умеешь!!!
                                    </p>
                                    <p>
                                        👩🏼‍💻💰 <strong>Пример как зарабатывать в LR:</strong>
                                    </p>
                                    <ul class="mb-5">
                                        <li class="mb-2"><strong>регистрируешься</strong> в компании</li>
                                        <li class="mb-2"><strong>получаешь</strong> огромную скидку 28% на всю продукцию</li>
                                        <li class="mb-2"><strong>покупаешь</strong> себе прибор</li>
                                        <li class="mb-2">я тебя всему <strong>обучаю</strong></li>
                                        <li class="mb-2"><strong>начинаешь делать</strong> процедуры, продавать продукт, регистрировать тех, кто хочет заработать и зарабатывать хорошие деньги</li>
                                        <li class="mb-2"><strong>получаешь деньги</strong> за процедуру, процент за проданную продукцию, и процент с оборота тех, кто как ты и я, активно включаться в процесс.</li>
                                    </ul>
                                    <p>
                                        Как только ты набрал достаточное количество отзывов и клиентов, сработало сарафанное радио и их количество растет. Ты готов масштабироваться!
                                    </p>
                                    <p>
                                        Первое, на кого обрати внимание - это бьюти мастера. Ты можешь дополнительно к их стандартным процедурам предложить рабочую схему заработка благодаря прибору и продаже продукции компании. Ты можешь делать это офлайн по знакомым или упаковать предложение для бьюти мастеров в соцсетях в виде чек листа по заработку на приборе или консультации по заработку с сопровождением и обучением.
                                    </p>
                                    <p>
                                        <strong>Пример статуса:</strong>
                                    </p>
                                    <p>
                                        УВЕЛИЧУ ДОХОД БЬЮТИ МАСТЕРУ В 2 РАЗА ЗА МЕСЯЦ! <br />
                                        Забирай обучение, пока оно бесплатно!
                                    </p>
                                    <p>
                                        🌐 <strong>Схема:</strong>
                                    </p>
                                    <ol class="mb-7">
                                        <li class="mb-2">проводишь короткую консультацию обратившемуся к теме бьюти мастеру (или просто девочке которая делает тебе ногти)</li>
                                        <li class="mb-2">продаешь прибор</li>
                                        <li class="mb-2">зарабатываешь уже с продажи</li>
                                        <li class="mb-2">клиент за покупку прибора получает партнерство с компанией и скидку 28%, получает обучение и сопровождение от тебя как за счет прибора зарабатывать.</li>
                                        <li class="mb-2">партнер начинает зарабатывать, твой оборот растет. 📈</li>
                                    </ol>
                                    <p>
                                        По сути, бьюти мастер, просто уже имея базу клиентов, расширяет спектр услуг за счет прибора и продает сопутствующие продукты здоровья и красоты, тем самым не меняя профессии, увеличивает доход на своей экспертности!!!
                                    </p>
                                    <p>
                                        ТАКОЕ ЖЕ ПРЕДЛОЖЕНИЕ МОЖНО СДЕЛАТЬ НЕБОЛЬШИМ БЬЮТИ САЛОНАМ!!!
                                    </p>
                                    <p>
                                        💃🏼 <strong>БЬЮТИ ВЕЧЕРИНКИ.</strong>
                                    </p>
                                    <ol class="mb-7">
                                        <li class="mb-2">в своем городе даешь рекламу в соцсетях</li>
                                        <li class="mb-2">собираешь знакомых (особенно акцент на бьюти мастерах)</li>
                                        <li class="mb-2">просишь всех, кто со скидкой делает процедуру, тоже разместить рекламу мероприятия на страничке.👩🏼‍💻</li>
                                        <li class="mb-2">собираешь людей на мастер-класс по красоте не выходя из дома</li>
                                        <li class="mb-2">делаешь розыгрыш продукции и бесплатной процедуры</li>
                                        <li class="mb-2">презентацию продукции и конечно самого прибора</li>
                                        <li class="mb-2">проводишь пару бесплатных процедур тем, кто выиграл</li>
                                        <li class="mb-2">продаешь прибор, продукт и идею бизнеса через экспертность в красоте. 💥</li>
                                    </ol>
                                    <p>
                                        Также пригласить еще одного эксперта в близкой области, допустим нутрициолога, который расскажет как правильно питаться, чтобы быть красивым.
                                    </p>
                                    <p>
                                        🚀 <strong>В сетевом расти и зарабатывать можно большим количеством способов. Главное - действовать правильно и в большом объеме. А с прибором Zeitgard ты даришь своему окружению красоту и можем за счет этого расти в обороте</strong>💥💰🌟
                                    </p>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                        <span>
                                            <v-icon class="mr-2" color="blue">mdi-numeric-3-box-multiple</v-icon>
                                            Рекомендации от компании LR по работе с прибором
                                        </span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <p class="mt-10">
                                        <a href="/files/zg/Анкета к руководству по продажам.pdf" target="_blank">Анкета к руководству по продажам (pdf)</a>
                                    </p>
                                    <p class="mt-10">
                                        <a href="/files/zg/Руководство по продажам.pdf" target="_blank">Руководство по продажам (pdf)</a>
                                    </p>
                                    <p class="mt-10">
                                        <a href="https://taplink.cc/lr_zeitgard" target="_blank">Таплинк по Zeitgard от компании</a>
                                    </p>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                        <span>
                                            <v-icon class="mr-2" color="purple">mdi-numeric-4-box-multiple</v-icon>
                                            Инструкции по применению/использованию прибора
                                        </span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <p class="mt-10">
                                        <a href="https://teletype.in/@zeitgard/wo53NDKwmws" target="_blank">Инструкция по применению прибора Zeitgard</a>
                                    </p>
                                    <p class="mt-10">
                                        <a href="/files/zg/Руководство_по_использованию_прибора.pdf" target="_blank">Руководство_по_использованию_прибора (pdf)</a>
                                    </p>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                        <span>
                                            <v-icon class="mr-2" color="teal">mdi-numeric-5-box-multiple</v-icon>
                                            Ответы на вопросы
                                        </span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <p class="mt-10">
                                        <a href="https://youtu.be/z45FISvCGwA" target="_blank">Ответы на вопросы</a>
                                    </p>
                                    <p class="mt-10">
                                        <a href="/files/zg/FAQ LR ZEITGARD.pdf" target="_blank">FAQ LR ZEITGARD (pdf)</a>
                                    </p>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-card-text>

                </base-material-card>
            </v-col>
        </v-row>
        <v-row
            justify="center"
            v-if="!packageZg.paytime || packageZg.paytime < dateNow"
        >
            <v-col
                cols="12"
                lg="9"
                xl="7"
            >
                <base-material-card
                    color="red"
                    class="px-md-16 px-5 py-3"
                >
                    <template v-slot:heading>
                        <div class="display-2 font-weight-light">
                            Ошибка доступа
                        </div>

                        <div class="subtitle-1 font-weight-light">

                        </div>
                    </template>

                    <v-card-text
                        class="mt-10"
                    >
                        <p>
                            <v-icon
                                color="red"
                                class="mr-2"
                            >
                                mdi-alert-circle-outline
                            </v-icon>
                            У вас нет доступа к просмотру данной страницы.</p>
                        <p>
                            <v-icon
                                class="mr-2"
                                color="blue"
                            >
                                mdi-arrow-right-bold-outline
                            </v-icon>
                            Для получения доступа к инструментам обратитесь к куратору:
                            <router-link
                                color="success"
                                :to="{name: 'PayInstruments'}"
                            >
                                Как получить доступ к инструментам
                            </router-link>
                        </p>
                    </v-card-text>
                </base-material-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "ZgInstrum",
    data: () => ({
        dateNow: new Date().toISOString().substr(0, 10),
        dialog: true,
        paytimeUser: ''
    }),
    computed: {
        ...mapGetters({
            packageZg: 'user/getProfileZg'
        }),
        datePay() {
            let D = new Date()
            D.setDate(D.getDate() + 7)
            return D.toISOString().substr(0, 10)
        }
    },
    created () {
        this.initializeUserPackage()
    },
    methods: {
        ...mapActions({
            loadPackageZg: 'user/loadProfileZg',
            loadProfilePackage: 'user/loadProfilePackage',
        }),
        copyRefLinkZg () {
            let referLinkZg = document.getElementById('referLinkZg')

            referLinkZg.select()

            document.execCommand('copy')
        },
        initializeUserPackage() {
            this.loadProfilePackage()
                .then((response) => {
                    this.loadingPackage = false
                    this.paytimeUser = response.data.paytime
                })
        },
    },
    mounted() {
        this.loadPackageZg()
        this.loadProfilePackage()
    }
}
</script>

<style lang="sass">
.exp-panel-title .v-expansion-panel-header
    font-size: 14px

.exp-panel-title .v-expansion-panel--active
    border: 1px solid #69db69

@media(max-width: 600px)
    .block-media-lk
        padding: 0

    .card-media-lk
        padding: 0

</style>
